import type { NextPage } from 'next';
import { Landing } from '../components/Landing/Landing';
import { Seo } from '../components/Seo/Seo';

const LandingPage: NextPage = () => {
  return (
    <>
      <Seo />
      <Landing />
    </>
  );
};

export default LandingPage;
