import Head from 'next/head';
import { HEADER_DESCRIPTION, HEADER_TITLE } from '../../constants/config';

interface SeoProps {
  title?: string;
  description?: string;
}

export const Seo = ({ title, description = HEADER_DESCRIPTION }: SeoProps) => {
  return (
    <Head>
      <title key="title">{`${title ? `${title} | ` : ``}${HEADER_TITLE}`}</title>
      <meta key="description" name="description" content={description} />
    </Head>
  );
};
