import React from 'react';
import { Button, ButtonProps } from '../Button/Button';
import { CaretDownIcon } from '../CaretDownIcon/CaretDownIcon';
import { RevealText } from '../RevealText/RevealText';
import { View } from '../View/View';
import styles from './ScrollDownButton.module.scss';

export interface ScrollDownButton extends Omit<ButtonProps, 'children'> {
  children?: string;
}

export const ScrollDownButton = ({
  children,
  text = 'button-labels',
  ...props
}: ScrollDownButton) => {
  return (
    <Button {...{ text, ...props }}>
      <View gap={4} justifyItems="center" className={styles.group}>
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
        <RevealText
          className={styles.text}
          when
          duration={500}
          loop={3000}
          from={4}
          to={-4}
        >
          {children}
        </RevealText>
        <CaretDownIcon title="scroll down" />
      </View>
    </Button>
  );
};
