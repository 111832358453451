import cx from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Mousewheel, Swiper as SwiperApi } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ROUTES } from '../../constants/routes';
import { useAppContext } from '../../contexts/AppContext/AppContext';
import { useOverlayContext } from '../../contexts/OverlayContext/OverlayContext';
import { Reveal } from '../Reveal/Reveal';
import { RevealText } from '../RevealText/RevealText';
import { ScrollDownButton } from '../ScrollDownButton/ScrollDownButton';
import { View } from '../View/View';
import styles from './Landing.module.scss';

enum SECTIONS {
  APPROACH = 0,
  MAP = 1,
}

export const Landing = () => {
  const { setTheme } = useAppContext();
  const { push } = useRouter();
  const { map, splash, setSplash, setMap } = useOverlayContext();

  const api = useRef<SwiperApi | null>(null);
  const [index, SetIndex] = useState(-1);

  const isApproach = index === SECTIONS.APPROACH;
  const isMap = index === SECTIONS.MAP;

  const onInit = (event: SwiperApi) => {
    api.current = event;
  };

  const onTransitionStart = ({ activeIndex }: SwiperApi) => {
    SetIndex(activeIndex);
  };

  const onTransitionEnd = ({ activeIndex }: SwiperApi) => {
    if (activeIndex === SECTIONS.MAP) {
      push(ROUTES.MAP);
    }
  };

  useEffect(() => {
    if (!splash?.loaded) return;
    setMap({ ...map, visible: true });
  }, [splash?.loaded]);

  useEffect(() => {
    setSplash({ visible: true, open: isMap });
    setTheme('light');
  }, [isMap]);

  useEffect(() => {
    SetIndex(0);
    setTheme('dark');
    return () => {
      setTheme('light');
      setSplash({ visible: true, open: true });
    };
  }, []);

  return (
    <>
      <main className={cx(styles.landing, isMap && styles.hide)}>
        <Swiper
          direction="vertical"
          slidesPerView={1}
          speed={1000}
          mousewheel
          modules={[Mousewheel]}
          className={styles.swiper}
          onInit={onInit}
          onTransitionStart={onTransitionStart}
          onTransitionEnd={onTransitionEnd}
        >
          <SwiperSlide>
            <View
              sideVariant="md"
              className={styles.actionSection}
              aria-hidden={!isApproach}
              as="section"
            >
              <View alignContent="center" gap={32}>
                <View as="h2" className={styles.actionContent} justifyItems="center">
                  <RevealText
                    variant="headline-l-bold"
                    desktopVariant="headline-xl-bold"
                    as="p"
                    className={styles.description}
                    when={isApproach}
                    letterDelay={20}
                    delay={1000}
                  >
                    California is burning and needs your help.
                  </RevealText>
                </View>
                <View
                  gap="2em"
                  as="p"
                  className={styles.actionContent}
                  justifyItems="center"
                >
                  <RevealText
                    variant="subhead-s"
                    desktopVariant="subhead-m"
                    className={styles.description}
                    when={isApproach}
                    letterDelay={5}
                    delay={1000}
                  >
                    Purchase a pop-art NFT of California’s landscape, with proceeds
                    funding wildfire prevention and reforestation. Built with Solana,
                    every transaction is carbon-offset.
                  </RevealText>
                  <RevealText
                    variant="subhead-s"
                    className={styles.description}
                    when={isApproach}
                    letterDelay={20}
                    delay={1500}
                  >
                    Scroll to see in real-time where your support is most needed.
                  </RevealText>
                </View>
              </View>
            </View>
          </SwiperSlide>
          <SwiperSlide></SwiperSlide>
        </Swiper>
        {isApproach && (
          <Reveal
            when={isApproach}
            delay={2000}
            className={styles.scrollDownButtonContainer}
          >
            <ScrollDownButton
              onClick={() => api.current?.slideNext()}
              disabled={!isApproach}
              tag="click_landing_startfighting"
            >
              Start Fighting
            </ScrollDownButton>
          </Reveal>
        )}
      </main>
    </>
  );
};
